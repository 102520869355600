<template>
  <div>
    <div v-if="skeleton"
         class="skeleton">
      <van-skeleton title
                    :row="20" />
    </div>
    <div class="trails"
         v-else>
      <p class="form_title">{{ formData.name }}</p>
      <div class="sel"
           v-for="item in formData.itemEntryList"
           :key="item.id">
        <h5>
          <span v-if="item.isRequire"
                style="color: red">*</span>
          {{ item.name }}
        </h5>
        <div v-if="item.type == 0">
          <input type="text"
                 v-model="obj[item.number]" />
        </div>
        <div v-else-if="item.type == 1">
          <textarea v-model="obj[item.number]" />
        </div>
        <div v-else-if="item.type == 2">
          <radio-group v-model="obj[item.number]">
            <div class="item"
                 v-for="(i, index) in getValue(item.value, item.number)"
                 :key="index">
              <radio :name="i.value">{{ i.name }}</radio>
            </div>
          </radio-group>
        </div>
        <div v-else-if="item.type == 3"
             style>
          <van-checkbox-group v-model="obj[item.number]">
            <div class="item"
                 v-for="(i, index) in getValue(item.value, item.number)"
                 :key="index">
              <van-checkbox :name="i.value"
                            :key="index">{{
                i.name
              }}</van-checkbox>
            </div>
          </van-checkbox-group>
        </div>
        <div v-else-if="item.type == 4">
          <input type="text"
                 readonly
                 @click="showPicker = true"
                 v-model="obj[item.number + 'name']" />
          <van-icon name="arrow-down"
                    class="down" />
          <van-popup v-model="showPicker"
                     round
                     position="bottom">
            <van-picker title="标题"
                        show-toolbar
                        :columns="getValue(item.value, item.number)"
                        value-key="name"
                        @cancel="showPicker = false"
                        @confirm="onConfirm" />
          </van-popup>
        </div>
        <div v-else-if="item.type == 5">
          <input type="text"
                 readonly
                 @click="
              dateKey = item.number;
              showdatetimePicker = true;
            "
                 v-model="obj[item.number]" />
          <van-icon name="arrow-down"
                    class="down" />

          <van-popup v-model="showdatetimePicker"
                     round
                     position="bottom">
            <van-datetime-picker @cancel="showdatetimePicker = false"
                                 @confirm="dateConfirm"
                                 v-model="currentDate"
                                 :min-date="minDate"
                                 type="datetime"
                                 title="选择年月日" />
          </van-popup>
        </div>
      </div>
      <van-button class="btn" 
                  size="small"
                  @click="submit"
                  v-if="formData.itemEntryList"
                  :loading="submitLoad"
                  :disabled="disabledF">
        立即提交
      </van-button>
      <qrcode v-model="show"
              :src="qrcode"
              text="您的信息我们已经收到，我们会尽快与您取得联系。长按识别二维码 立即联系"></qrcode>
    </div>
  </div>
</template>

<script>
import { RadioGroup, Radio } from "vant";
import qrcode from "@/components/qrcode";
import mixin from "../utils/share";
export default {
  mixins: [mixin],
  props: {},
  data () {
    return {
      radio: 1,
      radios: [],
      rotate: false,
      value: "",
      skeleton: true,
      disabledF: false,
      minDate: new Date(1970, 0, 1),
      show: false,
      submitLoad: false,
      qrcode: '',
      dateKey: "",
      currentDate: "",
      obj: {},
      showPicker: false,
      showdatetimePicker: false,
      query: {},
      formData: {}
    };
  },
  computed: {},
  created () { },
  mounted () {
    // setTimeout(() => {
    //   this.Action(1);
    // }, 3000);
    this.getFormData()
    this.getBusinessCardData()
  },
  watch: {},
  methods: {
    getFormData () {
      let id = this.$route.query.formId;
      this.$api.get(`/api/crm/form/get?id=${id}`).then((res) => {
        this.$nextTick(() => {
          this.skeleton = false;
        });
        if (res.result) {
          this.formData = res.result
        }
      });
    },
    getBusinessCardData () {
      let id = this.$route.query.businessCardId;
      this.$api.get(`/api/crm/businessCard/get?id=${id}`).then((res) => {
        console.log('999999', res)
        if (res.result) {
          this.qrcode = res.result.wechatQrCode
        }
      });
    },
    dateConfirm (val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      let timeValue = `${year}-${month}-${day} ${hour}:${minute}`;
      this.obj[this.dateKey] = timeValue;
      this.showdatetimePicker = false;
    },
    submit () {
      let obj = {
        formId: this.formData.id,
        value: [],
        pageId: this.$pageId,
      };
      this.formData.itemEntryList.forEach((i) => {
        if (i.isRequire) {
          if (this.obj[i.number] == null) {
            this.$toast(`${i.name}为必填内容`);
            throw `${i.name}为必填内容`;
          }
        }
        for (const key in this.obj) {
          let element = this.obj[key];

          if (Array.isArray(element)) {
            element = element.join(",");
          }
          if (key == i.number) {
            obj.value.push({
              id: i.id,
              code: i.number,
              name: i.name,
              value: element,
            });
          }
        }
      });
      obj.value = JSON.stringify(obj.value);
      this.submitLoad = true
      this.$api
        .post("/api/crm/formValue/save", {
          ...obj,
          channelId: this.$route.query.channelId,
          ladingPageId: this.$route.query.id,
          pageId: this.$pageId,
          articleId: this.$route.query.articleId,
          action: 4,
          businessCardId: this.$route.query.businessCardId,
          url: window.location.href,
          openId: this.$cookies.get("openId"),
          unionId: this.$cookies.get("unionId"),
        })
        .then((res) => {
          if ((res.code === "C0000")) {
            // this.$toast({
            //   message: "您的信息我们已经收到，我们会尽快与您取得联系。",
            //   className: "_toast",
            // });
            this.show = true
            this.disabledF = true;
          }
          this.submitLoad = false
        }).catch(() => {
          this.submitLoad = false
        })
    },
    onConfirm (e) {
      this.obj[e.key + "name"] = e.name;
      this.obj[e.key] = e.value;
      this.showPicker = false;
    },
    getValue (val, key) {
      // 男；女
      let arr = [];
      if (val.indexOf(";") > -1) {
        arr = val.split(";");
      } else {
        arr = val.split("；");
      }
      return arr.map((i) => {
        return {
          value: i,
          name: i,
          key: key,
        };
      });
    }
  },
  components: { RadioGroup, Radio, qrcode },
};
</script>
<style>
.van-checkbox__label,
.van-radio__label {
  font-size: 30px;
}
/* .content span {
  font-size: 28px !important;
}
.content p {
  font-size: 28px !important;
} */
</style>
<style scoped lang="less">
.comment_info {
  display: flex;
}
.comment_icon {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.comment_icon div {
  margin-right: 20px;
}
.comment {
  background: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  p {
    padding: 10px 0;
    font-size: 26px;
  }
  textarea {
    width: 100%;
    height: 300px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
  }
}
.rotate {
  transform: rotate(180deg);
}
.form_title {
  text-align: center;
  background: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
}
.down {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.desc {
  background: #ffffff;
  padding-top: 35px;
  p {
    text-align: center;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-bottom: 20px;
    img {
      width: 20px;
      height: 10px;
      margin: 5px;
      margin-left: 13px;
      position: relative;
      top: 1px;
    }
  }
  ul {
    padding: 0 22px;
    padding-top: 28px;
    padding-bottom: 20px;
    li {
      margin-bottom: 20px;
      display: flex;
      .span1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b8b8b8;
      }
      .span2 {
        font-size: 0.32rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363636;
        padding-left: 0.26667rem;
        /* max-width: 7.06667rem; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        flex: 1;
        padding-right: 0.4rem;
      }
      .span3 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6e03;
        text-align: right;
      }
    }
  }
}
.trails {
  background: #f9f9f9;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  .ad {
    margin: 20px 0;
    background: #fff;
    img {
      height: 400px;
      width: 100%;
    }
  }
  .btn:disabled{
    background: #eee;
    color: #333;
  }
  .btn {
    height: 88px;
    background: #ff6e03;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 88px;
    width: 90%;
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
  .back_btn {
    background: #fff;
    border: 1px solid #ccc;
    color: #363636;
    margin-top: 30px;
  }
  .sel {
    background: #fff;
    padding: 10px 20px;
    overflow: hidden;
    position: relative;
    .item {
      float: left;
      width: 50%;
      margin-bottom: 34px;
    }
    h5 {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #838383;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    input {
      width: 100%;
      height: 60px;
      // border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 10px;
      border: 0;
      border-bottom: 1px solid #ccc;
      padding-left: 10px;
      font-size: 32px;
    }
    textarea {
      width: 100%;
      padding: 10px;
      height: 120px;
      border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      box-sizing: border-box;
      font-size: 32px;
    }
  }
  .article {
    padding: 40px 23px;
    background: #fff;
    margin-top: 16px;
    .content {
      margin-top: 46px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
      line-height: 48px;
      text-align: justify;
      padding: 0 10px;
      box-sizing: border-box;
      span {
        font-size: 24px !important;
      }
      p {
        font-size: 24px !important;
      }
    }
    h3 {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #363636;
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      span {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c3c3c3;
      }
    }
  }
  .card {
    display: flex;
    height: 160px;
    align-items: center;
    background: #fff;
  }
  .text {
    flex: 1;
  }
  .img1 {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin-left: 43px;
    margin-right: 18px;
  }
  .p1 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #363636;
  }
  .p2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b8b8b8;
    padding-top: 6px;
  }
  .img2 {
    width: 50px;
    height: 50px;
    margin-right: 26px;
    margin-top: 10px;
  }
}
</style>
